html, body {
  height: 100%;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}
